import { Link } from "gatsby";
import React from "react";

const Page404 = () => (
  <div className="container mt-6 pt-3 mb-6 pb-5">
    <div className="content page404 has-text-centered">
      <h1 className="mb-4">404</h1>
      <h2>Oops! The page can’t be found.</h2>
      <div className="mt-3">
        Go to{" "}
        <Link to={"/"} className="link">
          homepage
        </Link>
      </div>
    </div>
  </div>
);

export default Page404;

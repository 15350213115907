import * as React from "react"
import Layout from "../components/layout/Layout"
import Page404 from "../components/layout/Page404"

const pageNotFound = () => (
  <Layout is404>
    <Page404/>  
  </Layout>
)

export default pageNotFound
